<template>
  <div id="app" :class="theme">
    <Header v-if="$route.name !== 'Login' && $route.name !== 'Impressum' && $route.name !== 'Datenschutz'"/>
    <b-overlay :show="loading" :variant="theme" :spinner-variant="spinnerTheme" :style="{ height: height }">
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner :variant="spinnerTheme" style="width: 5rem; height: 5rem;"/>
        </div>
      </template>
      <b-container fluid>
        <b-row class="main">
          <b-col cols="12">
            <router-view v-if="show" :key="$route.fullPath"/>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
    <Footer class="footer w-100"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Footer from "@/components/structure/Footer";

const Header = () => import(/* webpackChunkName: "header" */ './components/structure/Header')

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      show: false,
      theme: '',
      spinnerTheme: 'white'
    }
  },
  watch: {
    userThemeId() {
      if(this.user && this.user.user_setting) {
        let body = document.querySelector('body')
        if(this.user.user_setting.frontend_theme_id === 2) {
          body.classList.remove('dark')
          body.classList.add('light')
        }
        else {
          body.classList.remove('light')
          body.classList.add('dark')
        }
        this.spinnerTheme = this.user.user_setting.frontend_theme_id !== 2 ? 'white' : 'dark'
        this.theme = this.user.user_setting.frontend_theme_id !== 2 ? 'dark' : 'light'
      }
    }
  },
  methods: {
    ...mapActions(['authCheck']),
    ...mapActions(['getSites']),
    ...mapActions(['getTeams']),
    ...mapActions(['selectSite']),
    ...mapActions(['setLoadingState']),
  },
  computed: {
    ...mapGetters(['authenticated']),
    ...mapGetters(['user']),
    ...mapGetters(['loading']),
    ...mapGetters(['siteSelected']),
    ...mapGetters(['userThemeId']),
    height() {
      if (this.loading) {
        return '95vh'
      } else {
        return '100%'
      }
    }
  },
  async beforeMount() {
    this.setLoadingState(true)
    if(this.authenticated) {
      await this.authCheck()
      if(!this.authenticated) {
        await this.$router.push({name: 'SessionExpired'})
      }
      else {
        await this.getTeams()
        await this.getSites()
        if(this.$route.query && this.$route.query.site) {
          await this.selectSite(this.$route.query.site)
        }
        else if(!this.siteSelected) {
          await this.selectSite(this.user.site.shortname)
        }
      }
      let body = document.querySelector('body')
      if(this.user.user_setting.frontend_theme_id === 2) {
        body.classList.remove('dark')
        body.classList.add('light')
      }
      else {
        body.classList.remove('light')
        body.classList.add('dark')
      }
      this.theme = this.user.user_setting.frontend_theme_id === 2 ? 'light' : 'dark'
    }
    this.show = true
  }
}
</script>

<style lang="scss">
</style>
