import axios from 'axios'

const state = {
    authenticated: false,
    user: null,
    userThemeId: 1,
    token: null,
}

const getters = {
    authenticated: (state) => state.authenticated,
    user: (state) => state.user,
    userThemeId: (state) => state.userThemeId,
    token: (state) => state.token,
}

const actions = {
    async signIn({commit}, credentials) {
        let error_msg = null
        await axios.post(process.env.VUE_APP_API_AUTH_LDAP_LOGIN, credentials)
            .then((response) => {
                commit('SET_AUTHENTICATED', true)
                commit('SET_USER', response.data.user)
                commit('SET_USER_THEME_ID', response.data.user.user_setting.frontend_theme_id)
                commit('SET_TOKEN', response.data.token)
                axios.defaults.headers.common = {
                    'Authorization': 'Bearer ' + response.data.token
                }
            })
            .catch((error) => {
                error_msg = error.response.data.errors
            })
        return error_msg
    },
    async signOut({commit}) {
        await axios.post(process.env.VUE_APP_API_AUTH_LDAP_LOGOUT)
            .then(() => {
                commit('RESET')
                delete axios.defaults.headers.common["Authorization"];
            })
            .catch(() => {
                commit('RESET')
                delete axios.defaults.headers.common["Authorization"];
            })
    },
    async authCheck({commit}) {
        await axios.get(process.env.VUE_APP_API_AUTH_LDAP_AUTH_CHECK)
            .catch(() => {
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
            })
    },
    async revokeTokens() {
        await axios.post(process.env.VUE_APP_API_AUTH_LDAP_TOKENS_REVOKE)
    },
    setUserSettings({commit}, userSettings) {
        commit('SET_USER_SETTINGS', userSettings)
    },
    setUserThemeId({commit}, userThemeId) {
        commit('SET_USER_THEME_ID', userThemeId)
    }
}

const mutations = {
    SET_AUTHENTICATED(state, value) {
        state.authenticated = value
    },
    SET_USER(state, value) {
        state.user = value
    },
    SET_TOKEN(state, value) {
        state.token = value
    },
    SET_USER_SETTINGS(state, value) {
        state.user.user_setting = value
    },
    SET_USER_THEME_ID(state, value) {
        state.userThemeId = value
    },
    RESET(state) {
        state.authenticated = false
        state.user = null
        state.userThemeId = 1
        state.token = null
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
